import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "master-data"
    }}>{`Master Data`}</h1>
    <p>{`Sebelum mulai melakukan transaksi penjualan ataupun pembelian user disarankan untuk terlebih dahulu menyiapkan Master Data yang diperlukan untuk kelengkapan daripada database yang digunakan. Dalam pengisian Master Data pada umumnya dapat dilakukan dengan dua cara yakni melalui import file csv atau memasukkannya satu persatu.`}</p>
    <p>{`Berikut beberapa Master Data yang perlu dipersiapkan`}</p>
    <h2 {...{
      "id": "daftar-account-atau-coa"
    }}>{`Daftar Account atau CoA`}</h2>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar CoA adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`account_type_id`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`code account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nama account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`deskripsi account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id account type`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "keterangan-"
    }}>{`Keterangan :`}</h4>
    <p>{`Flits menyediakan 28 Tipe Akun yang dapat digunakan`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Kas & Bank`}</li><li>{`Piutang`}</li><li>{`Aktiva Lancar Lainnya`}</li><li>{`Persediaan`}</li><li>{`Aktiva Tetap`}</li><li>{`Aktiva Lainnya`}</li><li>{`Depresiasi & Amortisasi`}</li><li>{`Akun Hutang`}</li><li>{`Kartu Kredit`}</li><li>{`Kewajiban Jangka Panjang`}</li><li>{`Ekuitas`}</li><li>{`Pendapatan`}</li><li>{`Pendapatan Lainnya`}</li><li>{`Harga Pokok Penjualan`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Beban`}</li><li>{`Beban Lainnya`}</li><li>{`Biaya Dibayar Dimuka,`}</li><li>{`Aktiva Tidak Lancar Lainnya`}</li><li>{`Hutang`}</li><li>{`Hutang Jangka Panjang`}</li><li>{`Laba (Rugi) Tahun Berjalan`}</li><li>{`Potongan Penjualan`}</li><li>{`Biaya`}</li><li>{`Biaya Lainnya`}</li><li>{`Retur Penjualan`}</li><li>{`Kewajiban Lancar Lainnya`}</li><li>{`Dividen`}</li><li>{`Akumulasi Penyusutan`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat akun baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Chart of Account dengan klik Accounting Pada Side Menu > Konfigurasi > Chart of Account > Pada list Chart of Account klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan kode Account (Kode antar account tidak boleh sama)`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan nama Account yang hendak dibuat`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih tipe Account sesuai list yang telah disediakan`}</p>
            <img src='/public/img/Setup Master Data/Bikin CoA.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Account yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "daftar-pajak"
    }}>{`Daftar Pajak`}</h2>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar Pajak adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`tax_type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`percentage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`include`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`account_id`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nama pajak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tipe pajak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`persentase nilai pajak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pajak "include" / "exclude"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id account`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "keterangan--1"
    }}>{`Keterangan :`}</h4>
    <ul>
      <li parentName="ul">{`Kolom tax_type diisi dengan `}<strong parentName="li">{`“sales”`}</strong>{` / `}<strong parentName="li">{`“purchases”`}</strong>{` / `}<strong parentName="li">{`“none”`}</strong></li>
      <li parentName="ul">{`Kolom include diisi dengan `}<strong parentName="li">{`“true”`}</strong>{` untuk pajak include atau `}<strong parentName="li">{`“false”`}</strong>{` untuk pajak exclude`}</li>
      <li parentName="ul">{`Kolom account_id diisi sesuai `}<strong parentName="li">{`ID`}</strong>{` dari akun yang hendak dipilih`}</li>
    </ul>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat pajak baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Pajak dengan klik Accounting Pada Side Menu > Konfigurasi > Pajak > Pada list Pajak klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nama Pajak`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Type Pajak, terdapat tiga pilihan jenis pajak “Penjualan”, “Pembelian” dan “None”`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih nama akun untuk menyimpan pajak tersebut, (List Akun muncul sesuai akun yang ada pada master akun)`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan presentase pajak tersebut`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Tentukan jenis Pajak “Include” atau “Exclude”`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Setelah semua telah field terisi, kemudian klik Save untuk menyimpan data pada database`}</p>
            <img src='/public/img/Setup Master Data/Bikin Tax.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Pajak yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "daftar-bank-dan-kas"
    }}>{`Daftar Bank dan Kas`}</h2>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar Bank dan Kas adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`account_no`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`account_id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`is_cash`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nama bank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nomor bank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`kode bank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tipe bank kas atau bank`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "keterangan--2"
    }}>{`Keterangan :`}</h4>
    <ul>
      <li parentName="ul">{`Kolom account_no diisi sesuai nomor rekening Bank / Kas jika ada`}</li>
      <li parentName="ul">{`Kolom code diisi dengan tiga karakter yang akan digunakan sebagai prefiks untuk nomor jurnal yg terbentuk`}</li>
      <li parentName="ul">{`Kolom account_id diisi sesuai ID dari akun yang hendak dipilih`}</li>
      <li parentName="ul">{`Kolom is_cash diisi dengan “true” / “false”`}</li>
    </ul>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat Bank dan Kas baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Bank & Kas dengan klik Bank & Kas Pada Side Menu > Bank & Kas Pada list Bank & Kas klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nama Bank & Kas`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan kode dengan 3 karakter, kode ini nantinya akan digunakan sebagai prefiks jurnal`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Nomor rekening wajib diisi apabila penyimpanan kas nya False`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Chart of Account`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih jenis penyimpanan berupa Bank atau Kas`}</p>
            <img src='/public/img/Setup Master Data/Bikin Bank & Kas.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Bank & Kas yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "daftar-customer"
    }}>{`Daftar Customer`}</h2>
    <p>{`Pada aplikasi Flits ini, menu Customer selain digunakan untuk menyimpan daftar pelanggan suatu perusahaan, juga digunakan sebagai kontrak terkait harga pengiriman suatu barang. Sehingga harga-harga yang muncul saat pembuatan dokumen penjualan akan mereferensi sesuai harga yang ada pada Master Customer ini.`}</p>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar Customer adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`phone`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`email`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`address`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`city_id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`postal_code`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nama customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`telepon customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`email customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alamat customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id kota`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`kode pos`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Keterangan :`}</p>
    <ul>
      <li parentName="ul">{`Kolom name diisi sesuai nama customer`}</li>
      <li parentName="ul">{`Kolom phone diisi sesuai nomor telepon customer`}</li>
      <li parentName="ul">{`Kolom email diisi sesuai alamat email customer`}</li>
      <li parentName="ul">{`Kolom city_id diisi ID dari pada kota sesuai database`}</li>
      <li parentName="ul">{`Kolom postal_code diisi kode pos sesuai alamat customer`}</li>
    </ul>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat Customer baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Customer dengan klik Customer Pada Side Menu Pada list Customer klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan nama Customer (Mandatory)`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan alamat lengkap (Mandatory) beserta Kota dan Kode Pos`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nomor Telepon Customer`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Alamat email`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan jumlah hari untuk Jatuh Tempo pembayaran untuk tagihan yang dibuatkan pada customer tersebut`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Untuk Customer Baru, untuk membuat rute dapat klik teks “Buat Rute untuk pelanggan ini”`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Create Rute dengan memilih Kota Start dan Kota Stop. Dapat juga membuat rute dengan lebih dari satu stop dengan cara klik tombol “Tambah Stop” lalu pilih kota yang dimaksud. Setelah rute sesuai klik tombol “Save Change”`}</p>
            <img src='/public/img/Setup Master Data/Bikin Customer Header.png' />
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Jarak tempuh untuk setiap rute`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Dalam satu Rute Pengiriman dapat dimasukkan beberapa item sekaligus dengan cara klik “Select Item” kemudian pilih item yang akan dipilih`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih satuan barang`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Set pengiriman Harga tiap item per unitnya. Satu item dapat mengandung lebih dari dua harga sebagai referensi dalam pembuatan SO`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Set Ongkos kirim yang akan diberikan kepada Supir per satuan barang`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Jika Sudah terisi lengkap, klik tombol centang pada kolom Action`}</p>
            <img src='/public/img/Setup Master Data/Bikin Customer Detail.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Data Customer yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "daftar-vendor"
    }}>{`Daftar Vendor`}</h2>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar Vendor adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`phone`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`email`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`address`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`city_id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`postal_code`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nama vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`telepon vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`email vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alamat vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id kota`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`kode pos`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "keterangan--3"
    }}>{`Keterangan :`}</h4>
    <ul>
      <li parentName="ul">{`Kolom name diisi sesuai nama Vendor`}</li>
      <li parentName="ul">{`Kolom phone diisi sesuai nomor telepon Vendor`}</li>
      <li parentName="ul">{`Kolom email diisi sesuai alamat email Vendor`}</li>
      <li parentName="ul">{`Kolom city_id diisi ID dari pada kota sesuai database`}</li>
      <li parentName="ul">{`Kolom postal_code diisi kode pos sesuai alamat Vendor`}</li>
    </ul>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat Vendor baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Customer dengan klik Vendor Pada Side Menu Pada list Vendor klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan nama Vendor (Mandatory)`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan alamat lengkap (Mandatory) beserta Kota dan Kode Pos`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nomor Telepon Vendor`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Alamat email`}</p>
            <img src='/public/img/Setup Master Data/Bikin Vendor Header.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Data Vendor yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "daftar-kendaraan"
    }}>{`Daftar Kendaraan`}</h2>
    <p>{`Daftar Kendaraan adalah list kendaraan yang dimiliki perusahaan yang digunakan untuk melakukan pengiriman atas Sales Order dari Customer.  Jumlah maksimal kendaraan dapat dilihat pada bagian kiri atas di halaman List Kendaraan.`}</p>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar Kendaraan adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`license_plate`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`stnk`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`license_expiry_date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`group_id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`type_id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`kir_date`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`plat nomor kendaraan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nomor stnk`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`masa berlaku stnk`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id tipe`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tanggal kir`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Keterangan :`}</p>
    <ul>
      <li parentName="ul">{`Kolom license_plate diisi sesuai Nomor Polisi Kendaraan`}</li>
      <li parentName="ul">{`Kolom stnk diisi sesuai nomor STNK kendaraan`}</li>
      <li parentName="ul">{`Kolom license_expiry_date diisi sesuai masa berlaku STNK`}</li>
      <li parentName="ul">{`Kolom kir_date diisi sesuai tanggal jatuh tempo KIR`}</li>
      <li parentName="ul">{`Format tanggal adalah (DD/MM/YYYY)`}</li>
    </ul>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat Kendaraan baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Customer dengan klik Fleets Pada Side Menu > Kendaraan Pada list Kendaraan klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan nomor polisi kendaraan pada field Nomor Registrasi, kemudian masukkan pula Nomor STNK dan Masa berlakunya`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan tanggal waktu KIR kendaraan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Grup kendaraan dan Tipe kendaraan sebagai penanda kendaraan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih merek sesuai merek kendaraan`}</p>
            <img src='/public/img/Setup Master Data/Bikin Kendaraan.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Data Kendaraan yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "daftar-supir"
    }}>{`Daftar Supir`}</h2>
    <p>{`Daftar Supir merupakan salah satu data yang harus dilengkapi terlebih dahulu sebelum melakukan transaksi penjualan. Karena setiap Sales Order yang diterbitkan harus di Assign kepada Supir yang bertugas mengirimkan pesanan.`}</p>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar Supir adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`fullname`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`address`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`city_id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`dob`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`license`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`license_issue_date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`license_expiry_date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`is_using_app`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`driver_status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`password`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`phone`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nama supir`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alamat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id kota`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ttl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nomor sim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tanggal buat sim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tanggal sim expired`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true / false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`status supir`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nomor telepon`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "keterangan--4"
    }}>{`Keterangan :`}</h4>
    <ul>
      <li parentName="ul">{`Kolom license diisi sesuai nomor SIM yang dimiliki oleh Supir`}</li>
      <li parentName="ul">{`Kolom license_issue_date diisi sesuai tanggal registrasi SIM`}</li>
      <li parentName="ul">{`Kolom license_expiry_date diisi sesuai tanggal kadaluarsa SIM`}</li>
      <li parentName="ul">{`Kolom is_using_app diisi “true” atau “false” yang digunakan sebagai penanda apakah Supir tersebut menggunakan Aplikasi atau Tidak`}</li>
      <li parentName="ul">{`Kolom password wajib diisi apabila kolom is_using_app diisi true`}</li>
      <li parentName="ul">{`Format tanggal adalah (DD/MM/YYYY)`}</li>
    </ul>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat Supir baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Customer dengan klik Fleets Pada Side Menu > Supir Pada list Supir klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nama Lengkap dan Nomor Telepon supir`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Alamat Lengkap Supir, termasuk pilih kota dan masukkan kode posnya apabila diperlukan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan tanggal lahir supir (Ada batasan umur supir harus diatas 17 tahun)`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Identitas SIM yang dimiliki supir dari Nomor SIM, Tanggal Registrasi hingga Masa Berlakunya SIM`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Checklist pada checkbox Driver App apabila supir yang dibuat menggunakan Aplikasi Flits Driver dalam menjalankan tugas, kemudian harus diatur passwordnya untuk dapat login ke Aplikasi Flits Driver`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Status Supir, terdapat tiga status :`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`- “Aktif” artinya Supir masih dapat melakukan pengiriman barang
- “Tidak Aktif” artinya Supir tidak dapat melakukan pengiriman barang
- “Terblokir” artinya Supir memiliki citra buruk dan tidak dapat diberi tugas pengiriman barang
`}</code></pre>
            <img src='/public/img/Setup Master Data/Bikin Supir.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Data Supir yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "daftar-karyawan"
    }}>{`Daftar Karyawan`}</h2>
    <p>{`Daftar Karyawan merupakan salah satu data yang harus dilengkapi terlebih dahulu sebelum melakukan transaksi penjualan. Karena setiap Sales Order yang diterbitkan harus di Assign kepada Supir yang bertugas mengirimkan pesanan.`}</p>
    <p>{`Format file .csv yang dapat digunakan untuk mengimport Daftar Karyawan adalah sebagai berikut,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`nik`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`full_name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`address`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`city_id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`dob`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`gender`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`employment_status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`marital_status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`email`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`job_title`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`phone`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nik`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nama karyawan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`alamat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id kota`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ttl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`gender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`status karyawan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`status perkawinan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`jabatan`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no. telepon`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Keterangan :`}</p>
    <ul>
      <li parentName="ul">{`Kolom gender diisi “female” untuk Perempuan dan “male” untuk Laki-laki`}</li>
      <li parentName="ul">{`Kolom dob diisi sesuai tanggal lahir`}</li>
      <li parentName="ul">{`Kolom marital_status diisi sesuai status kawin dari karyawan tersebut`}</li>
      <li parentName="ul">{`Kolom job_title diisi sesuai posisi karyawan`}</li>
      <li parentName="ul">{`Kolom employment_status diisi dengan “fulltime” / “contract” / “parttime”`}</li>
      <li parentName="ul">{`Format tanggal adalah (DD/MM/YYYY)`}</li>
    </ul>
    <p>{`Selain memasukkan data melalui import file .csv dapat juga dibuat satu persatu, berikut langkah-langkah untuk membuat Karyawan baru :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Customer dengan klik Konfigurasi Pada Side Menu > Karyawan Pada list Karyawan klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Lengkapi setiap field seperti gambar dibawah :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Masukkan identitas lengkap karyawan seperti NIK, Nama Lengkap, Jenis Kelamin dan Status kawin`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Alamat tinggal lengkap, sekaligus Kota dan Provinsi`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Alamat Email karyawan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nomor Telepon yang bisa dihubungi`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Tanggal lahir karyawan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan status karyawan tersebut pada perusahaan mulai dari Divisi, Judul Pekerjaan dan termasuk dalam Tipe Karyawan yang mana.`}</p>
            <img src='/public/img/Karyawan dan User/Bikin Karyawan.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik Save untuk menyimpan Data Karyawan yang telah dibuat kedalam database`}</p>
      </li>
    </ul>
    <p>{`Masing-masing dari karyawan dapat diinvite sebagai user yang artinya karyawan tersebut dapat melakukan login ke aplikasi Flits dengan permission tertentu.`}</p>
    <p>{`Berikut langkah-langkah untuk mengundang seorang karyaman menjadi user dengan permission tertentu :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Masuk ke Halaman List Karyawan dengan cara klik Konfigurasi pada side Menu > Karyawan`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Cari Nama Karyawan yang akan diundang sebagai user kemudian masuk ke Detail Karyawan`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Masuk ke Tab Invite User lalu klik tombol Invite (Langkah ini akan mengirimkan email invitation kepada karyawan yang bersangkutan)`}</p>
        <img src='/public/img/Karyawan dan User/Invite User.png' />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Tunggu beberapa saat sampai muncul Tab untuk Atur Password dan Permission `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Atur permission untuk karyawan yang baru diundang dengan cara klik Edit Formulir Karyawan > Klik Tab Hak Akses`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Secara default Role / Hak Akses akan terisi “Basic” namun masih bisa ditambahkan dengan Role yang lain dengan cara Klik Tombol “Add Role”`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pilih Role yang akan diberikan pada user tersebut kemudian klik tombol centang pada role yang baru ditambahkan`}</p>
        <img src='/public/img/Karyawan dan User/Atur Permission User.png' />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Setelah semua pengaturan selesai Klik Tombol Save untuk menyimpan semua perubahan tadi`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      